export enum MovementType {
  Arrival = 'Arrival',
  Departure = 'Departure',
  Shift = 'Shift',
}

export enum PilotageModeType {
  Direct = 'Direct',
  Indirect = 'Indirect',
}

export enum TransferMethodType {
  PilotLaunch = 'Pilot Launch',
  Helicopter = 'Helicopter',
  Tug = 'Tug',
  Wharf = 'Wharf',
  Car = 'Car'
}

export enum SideTo {
  Port = 'Port',
  Starboard = 'Starboard',
}

export enum BerthStationType {
  Station = 'station',
  Berth = 'berth',
}

export interface PilotageLocation {
  berthStationType: BerthStationType
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  name: string
  uuid: string
}

export enum PilotageStatus {
  Draft = 'Draft',
  New = 'New',
  Plan = 'Plan',
  SentUnread = 'SentUnread',
  SentRead = 'SentRead',
  SentNewComment = 'SentNewComment',
  Done = 'Done',
  Arch = 'Arch',
}
