import { string } from 'yup'

export const REGEX_VESSEL_NAME_WITH_NUMBER_VALIDATION = /^[^~!@#$%^*+=\\|\][{}";:?>,<]*$/

function vesselValidator(values) {
  const errors = {}

  const nameTrimmed = (values.name || '').trim()
  if (!nameTrimmed) {
    errors.name = 'Required'
  }

  if (!REGEX_VESSEL_NAME_WITH_NUMBER_VALIDATION.test(nameTrimmed)) {
    errors.name = 'Only letters, numbers and the symbols ()._-/’'
  }

  const numericFields = [
    'metadata_displacement_summer',
    'metadata_DWT_summer',
    'metadata_draft_summer',
    'height',
    'length',
    'beam',
    'bridgeToStern',
    'bridgeToBow',
    'metadata_gross_tonnage',
    'metadata_net_tonnage',
    'metadata_thr_fwd',
    'metadata_thr_aft',
    'metadata_main_engine',
    'metadata_me_limited_power',
    'metadata_air_draft',
    'metadata_beamToFwdContBay',
    'metadata_sternToAftContBay',
    'bittsFwd',
    'bittsAft'
  ]

  numericFields.forEach(fieldName => {
    if (values[fieldName]) {
      const number = Number(values[fieldName])

      if (isNaN(number) || values[fieldName].slice(-1) === '.') {
        errors[fieldName] = 'Must be numeric'
      } else if (number < 0) {
        errors[fieldName] = 'Must be non-negative'
      }
    }
  })

  if (values.metadata_year_built) {
    const numericYear = Number(values.metadata_year_built)
    // EMPX-720 Seaweb data import has ships with future year built dates, disable year check for now.
    if (
      isNaN(numericYear) ||
      numericYear < 1900 ||
      // numericYear > new Date().getFullYear() ||
      Math.round(numericYear) !== numericYear
    )
      errors.metadata_year_built = 'Invalid year'
  }

  if (values.metadata_mmsi && !/^[0-9]{9}$/.test(values.metadata_mmsi.trim())) {
    errors.metadata_mmsi = 'Must be a 9-digit number'
  }

  if (values.email) {
    const emails = values.email.split(',').map(email => email.trim())
    for (let i = 0, n = emails.length; i < n; i++) {
      if (
        !string()
          .email()
          .isValidSync(emails[i])
      ) {
        errors.email = 'One or more email addresses are invalid'
        break
      }
    }
  }

  return errors
}

export default vesselValidator
